import React from "react";
import Input from "../Input/";
import { footerNav } from "../../data/data";
import "./style.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__wrapper__about">
          <h3 className="footer__wrapper__about__title">About the brand</h3>
          <p className="footer__wrapper__about__paragraph">
            Imperfect celebrates individuality, embracing imperfections. True
            beauty lies in uniqueness. Our mission empowers self-expression,
            challenges norms, promotes self-acceptance. Everyone has a story;
            quirks and flaws define us. Join our community to live
            authentically, on your own terms.
          </p>
        </div>
        <div className="footer__wrapper__links">
          <h4 className="footer__wrapper__links__title">Menu</h4>
          <div className="footer__wrapper__links__list">
            {footerNav.map((i) => (
              <Link
                to={i.link}
                key={i.id}
                className="footer__wrapper__links__list__item"
              >
                {i.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="footer__wrapper__newsletter">
          <h3 className="footer__wrapper__newsletter__title">Newsletter</h3>
          <p className="footer__wrapper__newsletter__subtitle">
            Subscribe to receive exclusive deals, updates and more.
          </p>
          <div className="footer__wrapper__newsletter__form">
            <Input
              className="footer__wrapper__newsletter__form__field"
              placeholder="Enter your Email"
              required
            />

            <i className="footer__wrapper__newsletter__form__icon ti ti-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
