import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Title from "../../Components/Title";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import SubTitle from "../../Components/SubTitle";
import "./style.scss";
import LoadingAnimation from "../../Components/LoadingAnimation";

const Contact = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);

      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="contact">
      {loading && <LoadingAnimation />}

      <Header isContainer className="header--desktop--v2" />
      <div className="contact__wrapper">
        <div className="contact__wrapper__info">
          <Title className="" label="Contact Us" />
          <SubTitle
            className="contact__wrapper__subtitle"
            label="For all enquiries relating to orders and returns please contact - imperfect.clothing.lb@gmail.com - Physical address:
            Fakhoury center- st.charbel main road-hboub, jbeil-mount lebanon-40001"
          />
        </div>
        <div className="contact__wrapper__form">
          <Input setValue={() => {}} placeholder="Your Name" />
          <Input setValue={() => {}} placeholder="Your Email" />
          <Input setValue={() => {}} placeholder="Your Phone (optional)" />
          <Input setValue={() => {}} placeholder="Your Message" />
          <Button
            className="contact__wrapper__form__btn"
            variant="modern"
            label="Send Message"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
