import React from "react";
import "./style.scss";

const LoadingAnimation = () => {
  return (
    <div className="loading-bg">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingAnimation;
