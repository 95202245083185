import React, { useEffect, useState } from "react";
import "./style.scss";
import Hero from "../../Components/Hero";
import Header from "../../Components/Header";
import ProductsSlider from "../../Components/ProductsSlider";
import HomeCustomSection from "../../Components/HomeCustomSection";
import LoadingAnimation from "../../Components/LoadingAnimation";
import ProductGrid from "../../Components/ProductsGrid";
import { getAllProducts } from "../../Utils/fetching";

const Home = () => {
  const [productSliderItems, setProductSliderItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const sortOrder = [
    "obsidian infinity",
    "obsidian imperfect illusion",
    "white david on vacation",
    "black imperfect illusion totte bag",
    "grey imperfect illusion",
    "Obsidian David on Vacation",
  ];
  const sortOrderGridItems = [
    "white infinity",
    "obsidian imperfect illusion",
    "obsidian david on vacation",
    "obsidian imperfect perfection",
  ];
  let finalOrganizedProducts = [];
  let finalOrganizedGridProducts = [];
  useEffect(() => {
    const ProductFetching = async () => {
      // setProductSliderItems(await getProductsWithLimit(8));
      setProductSliderItems(
        // await getProductsByCategory(8, "over-sized-shirts")
        await getAllProducts()
      );
      setLoading(false);
      // setProductGridShortsItems(await getProductsByCategory(4, "shorts")); // save db reads
    };
    ProductFetching();
  }, []);

  sortOrder.forEach((keyword) => {
    productSliderItems.forEach((product) => {
      if (product.name_lowercase === keyword.toLowerCase()) {
        finalOrganizedProducts.push(product);
      }
    });
  });
  sortOrderGridItems.forEach((keyword) => {
    productSliderItems.forEach((product) => {
      // to save a db fetch
      if (product.name_lowercase === keyword.toLowerCase()) {
        finalOrganizedGridProducts.push(product);
      }
    });
  });

  return (
    <div className="home">
      {loading && <LoadingAnimation />}
      <Header />
      {!loading && <Hero />}

      {finalOrganizedProducts.length > 0 && (
        <ProductsSlider
          category="all"
          titleLabel="IMPERFECT PERFECTION"
          subTitleLabel="NEW SUMMER 2023 COLLECTION"
          productSliderItems={finalOrganizedProducts}
        />
      )}
      <HomeCustomSection />
      <ProductsSlider
        titleLabel="IMPERFECT SHORTS"
        category="shorts"
        subTitleLabel="2023 SUMMER COLLECTION"
        productSliderItems={productSliderItems.filter(
          (product) => product.collection === "shorts"
        )}
      />
      <ProductGrid
        titleLabel="IMPERFECT ESSENTIALISM"
        subTitleLabel="NEW SUMMER 2023 COLLECTION"
        productGridItems={finalOrganizedGridProducts}
      />
    </div>
  );
};

export default Home;
