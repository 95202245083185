import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "./style.scss";
import HeroContent from "../HeroContent";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__wrapper">
        <Swiper
          slidesPerView={1}
          pagination={true}
          modules={[Pagination, Navigation]}
        >
          <SwiperSlide>
            <div
              style={{
                backgroundImage: 'url("/static/images/3.jpeg")',
              }}
              className="hero__wrapper__background"
            >
              <div className="hero__wrapper__background__content">
                <HeroContent
                  subTitleLabel="IMPERFECT PERFECTION"
                  href="/collection/all"
                  titleClass="hero__wrapper__background__content__title"
                  titleLabel="SUMMER COLLECTION 2023"
                  btnClass="hero__wrapper__background__content__btn"
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
