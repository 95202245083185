import { Route, Routes } from "react-router-dom";
import "./general.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Footer from "./Components/Footer";
import Collection from "./Pages/Collection";
import Product from "./Pages/Product";
import Payment from "./Pages/Payment";
import NotFound from "./Pages/NotFound";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { CartProvider } from "./context/cartContext";
import Information from "./Pages/Information";
import Contact from "./Pages/Contact";
import OrderReceived from "./Pages/OrderReceived";
import CopyRight from "./Components/Copyright";
import ShippingAndReturnPolicy from "./Pages/DeliveryPolicy";
import TermsOfService from "./Pages/PrivactRefundPolicy";

function App() {
  useEffect(() => {
    AOS.init();
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.style.overflow = "auto";
    }, 1000);
    document.body.style.overflow = "hidden";
    return () => {
      clearTimeout(timer);
     document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <CartProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collection/:collection" element={<Collection />} />
        <Route path="/product/:collection/:id" element={<Product />} />
        <Route path="/information" element={<Information />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/delivery-return-policy"
          element={<ShippingAndReturnPolicy />}
        />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/order-received" element={<OrderReceived />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <CopyRight />
    </CartProvider>
  );
}

export default App;
