import React from "react";
import Header from "../../Components/Header";
import "./style.scss";

const NotFound = () => {
  return (
    <div className="notFound">
      <Header isContainer className="header--desktop--v2" />
      <div className="notFound__iconWrapper">
        <i className="ti ti-face-sad notFound__iconWrapper__icon"></i>
      </div>
      <h1 className="notFound__label">Page Not Found</h1>
    </div>
  );
};

export default NotFound;
