import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const SearchItem = ({ product, setIsSearchOpen }) => {
  return (
    <Link
      to={`/product/${product.collection}/${product.id}`}
      onClick={() => setIsSearchOpen((prev) => !prev)}
    >
      <div className="searchItem">
        <div className="searchItem__wrapper">
          <img
            src={product.mainImage}
            alt=""
            className="searchItem__wrapper__img"
          />
          <p className="searchItem__wrapper__name">{product.name}</p>
        </div>
      </div>
    </Link>
  );
};

export default SearchItem;
