import React from "react";
import "./style.scss";
import Card from "../Card";
import Button from "../Button";
import SubTitle from "../SubTitle";
import Title from "../Title";
import { Link } from "react-router-dom";

const ProductGrid = ({
  productGridItems,
  subTitleLabel = "NEW SUMMER 2023 COLLECTION",
  titleLabel = "IMPERFECT T-SHIRTS",
  href = "/collection/over-sized-shirts",
}) => {
  return (
    <div className="productGrid">
      <SubTitle className="" label={subTitleLabel} />
      <Title className="" label={titleLabel} />
      <div className="productGrid__wrapper">
        {productGridItems.map((item, index) => (
          <div key={item.id} className="productGrid__wrapper__item">
            <Link
              data-aos="fade-up"
              data-aos-delay={`${(index % 2) * 300}`}
              data-aos-easing="linear"
              key={item.id}
              to={`/product/${item.collection}/${item.id}`}
            >
              <Card
                id={item.id}
                img={item.mainImage}
                name={item.name}
                price={item.price}
              />
            </Link>
          </div>
        ))}
      </div>
      <Button
        className="productsSlider__wrapper__btn"
        href={href}
        label="DISCOVER MORE"
        variant="classic"
      />
    </div>
  );
};

export default ProductGrid;
