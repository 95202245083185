import React, { useState } from "react";
import SearchItem from "../SearchItem/";
import "./style.scss";
import { getProductsBySearch } from "../../Utils/fetching";

const Search = ({ isSearchOpen, setIsSearchOpen }) => {
  const [products, setProducts] = useState([]);
  const [fieldValue, setFieldValue] = useState("");
  const handleSearch = async (e) => {
    if (e.target.value.length > 1) {
      setProducts(await getProductsBySearch(e.target.value));
    }
  };
  return (
    <div className="search">
      <div
        className={`search__container ${
          isSearchOpen && "search__container--show"
        }`}
      >
        <div className="search__container__fieldWrapper">
          {isSearchOpen && (
            <input
              onChange={(e) => {
                handleSearch(e);
                setFieldValue(e.target.value);
              }}
              autoFocus
              type="text"
              className="search__container__fieldWrapper__field"
            />
          )}

          <i className="search__container__fieldWrapper__searchIcon ti ti-search"></i>
          <i
            className="search__container__fieldWrapper__closeIcon ti ti-close"
            onClick={() => {
              setIsSearchOpen(false);
            }}
          ></i>
        </div>
      </div>
      {products?.length > 0 && isSearchOpen && (
        <div className="search__results">
          <p className="search__results__title">
            {`${products.length} results`}
          </p>
          {products.map((item) => (
            <SearchItem
              key={item.id}
              setIsSearchOpen={setIsSearchOpen}
              product={item}
            />
          ))}
        </div>
      )}
      {products?.length < 1 && isSearchOpen && fieldValue.length > 1 && (
        <div className="search__results">
          <p className="search__results__title">0 Results</p>
        </div>
      )}
    </div>
  );
};

export default Search;
