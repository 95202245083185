import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header";
import Title from "../../Components/Title";
import SubTitle from "../../Components/SubTitle";
import OrderOverview from "../../Components/OrderOverview";
import { CartContext } from "../../context/cartContext";
import LoadingAnimation from "../../Components/LoadingAnimation";
import { useLocation, useNavigate } from "react-router-dom";
const OrderReceived = () => {
  const { clearCart, cart } = useContext(CartContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const { formData } = location.state;

  useEffect(() => {
    if (!cart.length) {
      navigate("/");
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);
    const timer2 = setTimeout(() => {
      navigate("/");
      clearCart();
    }, 4000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);

      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="orderReceived">
      {loading && <LoadingAnimation />}
      <Header isContainer className="header--desktop--v2" />
      <div className="orderReceived__wrapper">
        <SubTitle className="" label="Thanks You" />
        <Title className="" label="Your Order has been received" />
        <OrderOverview
          coupon={formData.percentage}
          shippingCost={formData.shippingCost}
        />
      </div>
    </div>
  );
};

export default OrderReceived;
