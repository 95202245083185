import React, { useContext, useEffect } from "react";
import "./style.scss";
import CardInCart from "../CardInCart";
import { CartContext } from "../../context/cartContext";
import { Link } from "react-router-dom";
const Cart = ({ setIsCartOpen, isCartOpen }) => {
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isCartOpen]);

  return (
    <div className="cart">
      <div
        className={`cart__wrapper ${isCartOpen ? "cart__wrapper--show" : ""}`}
      >
        <div className="cart__wrapper__header">
          <p className="cart__wrapper__header__label">CART</p>
          <i
            className="cart__wrapper__header__icon ti ti-close"
            onClick={() => {
              setIsCartOpen(false);
            }}
          ></i>
        </div>
        <div className="cart__wrapper__body">
          {cart.length > 0 &&
            cart.map((i) => <CardInCart key={i.id} product={i} />)}
        </div>
        {cart.length < 1 && (
          <div className="cart__wrapper__whenEmpty">
            <p className="cart__wrapper__whenEmpty__label">
              Your cart is empty
            </p>
          </div>
        )}
        <div className="cart__wrapper__footer">
          {cart.length > 0 && (
            <div
              className="product__wrapper__right__btnParent cart__wrapper__footer__btn"
              onClick={() => {
                setIsCartOpen(false);
              }}
            >
              <Link to="/information" className="aLink">
                <p className="product__wrapper__right__btnParent__btn cart__wrapper__footer__btn__label">
                  buy it now
                </p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
