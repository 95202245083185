import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDL8mfdRIkyWDS6fjku9EFCaUJXY_vq6KM",
  authDomain: "eliebejjani-ae524.firebaseapp.com",
  projectId: "eliebejjani-ae524",
  storageBucket: "eliebejjani-ae524.appspot.com",
  messagingSenderId: "683324390876",
  appId: "1:683324390876:web:af64cab3844b28f2dbe319",
};

const app = initializeApp(firebaseConfig);
export default app;
