import React, { createContext, useEffect, useReducer } from "react";

// Define the initial state of the cart
const initialState = {
  items: [],
  isCartOpened: false,
};

// Create the context
export const CartContext = createContext(initialState);

// Define the reducer function to modify the state
const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );

      if (existingItem) {
        const updatedItems = state.items.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );

        return { ...state, items: updatedItems };
      } else {
        return { ...state, items: [...state.items, action.payload] };
      }
    case "REMOVE_ITEM":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case "HANDLE_QUANTITY":
      const { id, operation } = action.payload;

      const updatedItems = state.items.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity:
                operation === "+" ? item.quantity + 1 : item.quantity - 1,
            }
          : item
      );

      return {
        ...state,
        items: updatedItems.filter((item) => item.quantity > 0),
      };
    case "SET_CART":
      return {
        ...state,
        items: action.payload,
      };

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
      };
    case "TOGGLE_CART":
      return {
        ...state,
        isCartOpened: action.payload,
      };
    default:
      return state;
  }
};

// Create the CartProvider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart && storedCart.length > 2) {
      dispatch({ type: "SET_CART", payload: JSON.parse(storedCart) });
    }
  }, []);

  // Save cart data to localStorage whenever cart state changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state.items));
  }, [state.items]);

  // Define actions to modify the state
  const addItem = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };
  const toggleCart = (boolean) => {
    dispatch({ type: "TOGGLE_CART", payload: boolean });
  };

  const removeItem = (itemId) => {
    dispatch({ type: "REMOVE_ITEM", payload: itemId });
  };
  const handleQuantity = (itemId, operation) => {
    dispatch({
      type: "HANDLE_QUANTITY",
      payload: { id: itemId, operation: operation },
    });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
    localStorage.removeItem("cart");
  };

  // Provide the state and actions to the children components
  return (
    <CartContext.Provider
      value={{
        cart: state.items,
        isCartOpened: state.isCartOpened,
        toggleCart,
        addItem,
        removeItem,
        clearCart,
        handleQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
