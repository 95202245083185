import React, { useContext, useState } from "react";
import "./style.scss";
import { CartContext } from "../../context/cartContext";

const CardInCart = ({ product }) => {
  const { removeItem, handleQuantity } = useContext(CartContext);

  return (
    <div className="cardInCart">
      <div className="cardInCart__wrapper">
        <img
          src={product?.mainImage}
          alt=""
          className="cardInCart__wrapper__img"
        />
        <div className="cardInCart__wrapper__details">
          <p className="cardInCart__wrapper__details__name">{product?.name}</p>
          <p className="cardInCart__wrapper__details__variant"></p>
          <p className="cardInCart__wrapper__details__price">
            {product?.price}$
          </p>
          <div className="cardInCart__wrapper__details__controls">
            <div className="product__wrapper__right__quantity cart">
              <div
                className="product__wrapper__right__quantity__control cart ti  ti-minus"
                onClick={() => handleQuantity(product.id, "-")}
              />
              <div className="product__wrapper__right__quantity__control__value cart">
                {product.quantity}
              </div>
              <div
                onClick={() => handleQuantity(product.id, "+")}
                className="product__wrapper__right__quantity__control cart ti  ti-plus"
              />
            </div>
            <i
              className="cardInCart__wrapper__details__controls__icon ti ti-trash"
              onClick={() => removeItem(product.id)}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInCart;
