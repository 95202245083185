import React from "react";
import "./style.scss";
const Input = ({
  placeholder = "",
  focus = false,
  // setValue,
  required = false,
  name = "",
  type = "text",
  onChange = () => {},
  className = "",
}) => {
  return (
    <input
      autoFocus={focus}
      type={type}
      name={name}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      className={`search__container__fieldWrapper__field ${
        className ? className : ""
      }`}
    />
  );
};

export default Input;
