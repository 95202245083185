import React from "react";
import MobileNavItem from "../MobileNavItem/";
import "./style.scss";
import { navigation } from "../../data/data";

const MobileHeader = ({ isMobileHeaderOpen, setIsMobileHeaderOpen }) => {
  return (
    <div className={`mobileHeader `}>
      <div
        className={`mobileHeader__wrapper ${
          isMobileHeaderOpen && "mobileHeader__wrapper--show"
        }`}
      >
        <i
          onClick={() => {
            setIsMobileHeaderOpen((prev) => !prev);
          }}
          className="ti ti-close mobileHeader__close"
        />
        {navigation.map((item) => (
          <MobileNavItem
            isMobileHeaderOpen={isMobileHeaderOpen}
            setIsMobileHeaderOpen={setIsMobileHeaderOpen}
            item={item}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileHeader;
