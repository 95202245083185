export const products = [
  {
    id: 14,
    name: "Obsidian Imperfect greek",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/pink_greek_1300x1300.jpg",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: ["red", "blue"],
    sizes: [],
    name_lowercase: "obsidian imperfect greek",
  },
  {
    id: 15,
    name: "Grey Imperfect greek",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/pink_yellow_grey_1300x1300.png",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/greyfront_94583345-116b-4af5-b5ce-6dae2bf1b2fd_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "grey imperfect greek",
  },
  {
    id: 17,
    name: "Obsidian Imperfect Illusion",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_aaj2a_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/front_28adc02c-b78e-495d-91ea-4a95a0903b23_1300x1300.jpg",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "obsidian imperfect illusion",
  },
  {
    id: 19,
    name: "White Imperfect Illusion",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/3aj2a_white_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_front_4345226b-a3c0-4faf-9df9-89309ca73a95_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect illusion",
  },
  {
    id: 21,
    name: "Grey Imperfect Illusion",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_aaj2a_grey_1300x1300.png",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/greyfront_630dc5ee-5959-40fc-9308-28c406ea57a2_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "grey imperfect illusion",
  },
  {
    id: 23,
    name: "White Imperfect greek",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/tinywow_pink_greek_22292458.webp",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_front_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect greek",
  },
  {
    id: 25,
    name: "White imperfect perfection",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_front_ead1e128-2279-462a-877b-2ea14338baef_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect perfection",
  },
  {
    id: 27,
    name: "Obsidian imperfect perfection",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_black_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/front_42cad271-9285-4a2a-bf08-ae99cfd77d38_1300x1300.jpg",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "obsidian imperfect perfection",
  },
  {
    id: 29,
    name: "Obsidian Infinity",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/japanese_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/front_13576ddb-2036-481e-be42-a5022958668a_1300x1300.jpg",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "obsidian infinity",
  },
  {
    id: 31,
    name: "White Infinity",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/japanese_1_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_front_8bb89d15-400e-41fe-a8e5-8ef6099b5232_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white infinity",
  },
  {
    id: 33,
    name: "Obsidian David on Vacation",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/yellow_greek_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/front_1300x1300.jpg",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "obsidian david on vacation",
  },
  {
    id: 35,
    name: "White David on Vacation",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/yellow_greek_1_1300x1300.jpg",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_white_front_7756e06a-8b41-4bfc-beda-49a160e8932d_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white david on vacation",
  },
  {
    id: 37,
    name: "Grey David on Vacation",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/yellow_greek_grey_1300x1300.png",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/greyfront_3193fcd2-6723-4751-a927-5c1877f6d2f2_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "grey david on vacation",
  },
  {
    id: 39,
    name: "Grey Infinity",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/japanese_black_grey_1300x1300.png",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/greyfront_d6301849-d768-4f71-84a3-ba03cc2a159c_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "grey infinity",
  },
  {
    id: 41,
    name: "Grey imperfect perfection",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_grey_1300x1300.png",
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/greyfront_1300x1300.png",
    ],
    collection: "over-sized-shirts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "grey imperfect perfection",
  },
  {
    id: 1,
    name: "Black imperfect Signature short",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/black_short_simple_900x900.jpg",
    ],
    collection: "shorts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "black imperfect signature short",
  },
  {
    id: 2,
    name: "White imperfect Signature short",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/white_short_simple_1300x1300.jpg",
    ],
    collection: "shorts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect signature short",
  },
  {
    id: 3,
    name: "White imperfect perfection short",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/white_short_1300x1300.jpg",
    ],
    collection: "shorts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect perfection short",
  },
  {
    id: 4,
    name: "Black imperfect perfection short",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/black_short_1300x1300.jpg",
    ],
    collection: "shorts",
    price: "30",
    colors: [],
    sizes: [],
    name_lowercase: "black imperfect perfection short",
  },
  {
    id: 5,
    name: "Black imperfect Infinity Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/black_japanese_tote_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "black imperfect infinity totte bag",
  },
  {
    id: 6,
    name: "White imperfect Infinity Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/japanese_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect infinity totte bag",
  },
  {
    id: 7,
    name: "White imperfect perfection Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect perfection totte bag",
  },
  {
    id: 8,
    name: "Black imperfect perfection Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/imperfect_black_tote_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "black imperfect perfection totte bag",
  },
  {
    id: 9,
    name: "Black Imperfect Illusion Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/tote_black_aaj2a_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "black imperfect illusion totte bag",
  },
  {
    id: 10,
    name: "White Imperfect Illusion Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/aaj2aa_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect illusion totte bag",
  },
  {
    id: 11,
    name: "White David on Vacation Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/yellow_imperfect_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "white david on vacation totte bag",
  },
  {
    id: 12,
    name: "Black David on Vacation Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/black_greek_y_tote_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "black david on vacation totte bag",
  },
  {
    id: 13,
    name: "White Imperfect greek Totte Bag",
    description: "",
    images: [
      "https://cdn.shopify.com/s/files/1/0730/3793/8984/files/yellow_pink_1300x1300.png",
    ],
    collection: "bags",
    price: "20",
    colors: [],
    sizes: [],
    name_lowercase: "white imperfect greek totte bag",
  },
];

export const navigation = [
  {
    id: 1,
    label: "Home",
    link: "/",
    items: [],
  },
  {
    id: 2,
    label: "Men",
    link: "",
    items: [
      {
        id: 5,
        label: "Imperfect T-shirts",
        link: "/collection/over-sized-shirts",
        items: [],
      },
      {
        id: 7,
        label: "Shorts",
        link: "/collection/shorts",
        items: [],
      },
      // {
      //   id: 1,
      //   label: "Caps",
      //   link: "/collection/caps",
      //   items: [],
      // },
      {
        id: 6,
        label: "Tote Bags",
        link: "/collection/bags",
        items: [],
      },
    ],
  },
  {
    id: 3,
    label: "Women",
    link: "",
    items: [
      {
        id: 5,
        label: "Imperfect T-shirts",
        link: "/collection/over-sized-shirts",
        items: [],
      },
      {
        id: 6,
        label: "Tote Bags",
        link: "/collection/bags",
        items: [],
      },
      // {
      //   id: 1,
      //   label: "Caps",
      //   link: "/collection/caps",
      //   items: [],
      // },
    ],
  },
  {
    id: 4,
    label: "Contact us",
    link: "/contact",
    items: [],
  },
  {
    id: 6,
    label: "About us",
    link: "/about",
    items: [],
  },
];
export const footerNav = [
  {
    name: "Home",
    id: 1,
    link: "/",
  },
  {
    name: "Shipping & Return Policy",
    id: 2,
    link: "/delivery-return-policy",
  },
  {
    name: "TERMS & CONDITIONS",
    id: 3,
    link: "/terms-of-service",
  },
  {
    name: "Contact us",
    id: 4,
    link: "/contact",
  },
  {
    name: "About",
    id: 5,
    link: "/about",
  },
];
export const shippingMethods = [
  {
    id: 1,
    label: "Express Shipping (1-3 Business Days) ",
    price: 3,
    zone: 1,
  },
  {
    id: 2,
    label: "DHL Express Shipping (2-6 Business Days)",
    price: 12,
    zone: 2,
  },
  {
    id: 3,
    label: "DHL Express Shipping (2-6 Business Days)",
    price: 22,
    zone: 3,
  },
];
export const PaymentMethods = [
  {
    id: 3,
    label: "Cash on Delivery (COD)",
  },
  {
    id: 4,
    label: "Coinbase Cryptocurrency",
  },
];
export const countries = [
  // First Group ($3)
  { id: 1, name: "Lebanon", price: 3, zone: 1 },

  // Second Group ($12)
  // { id: 2, name: "UAE", price: 12, zone: 2 },
  // { id: 3, name: "Qatar", price: 12, zone: 2 },
  // { id: 4, name: "Kuwait", price: 12, zone: 2 },
  // { id: 5, name: "France", price: 12, zone: 2 },
  // { id: 6, name: "Saudi Arabia", price: 12, zone: 2 },
  // { id: 7, name: "Egypt", price: 12, zone: 2 },
  // { id: 8, name: "Italy", price: 12, zone: 2 },
  // { id: 9, name: "Bahrain", price: 12, zone: 2 },
  // { id: 10, name: "Singapore", price: 12, zone: 2 },
  // { id: 11, name: "United Kingdom", price: 12, zone: 2 },
  // { id: 12, name: "Switzerland", price: 12, zone: 2 },
  // { id: 13, name: "Sweden", price: 12, zone: 2 },
  // { id: 14, name: "Spain", price: 12, zone: 2 },
  // { id: 15, name: "Germany", price: 12, zone: 2 },

  // // Third Group ($20)
  // { id: 16, name: "Canada", price: 20, zone: 3 },
  // { id: 17, name: "USA", price: 20, zone: 3 },
  // { id: 18, name: "Australia", price: 20, zone: 3 },
  // { id: 19, name: "Singapore", price: 20, zone: 3 },
  // { id: 20, name: "Angola", price: 20, zone: 3 },
  // { id: 21, name: "Nigeria", price: 20, zone: 3 },
];

// export const coupons = [
//   {
//     id: 1,
//     name: "OG20",
//     amount: 20,
//   },
// ];
export const COUPON__CODE = "OG20";
