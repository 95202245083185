import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import LoadingAnimation from "../../Components/LoadingAnimation";
import ProductsSlider from "../../Components/ProductsSlider";
import "./style.scss";
import { useParams } from "react-router-dom";
import { getProductById, getProductsByCategory } from "../../Utils/fetching";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../context/cartContext";
const Product = () => {
  const { id, collection } = useParams();
  const [product, setProduct] = useState({});
  const [colorSelected, setColorSelected] = useState("");
  const [sizeSelected, setsizeSelected] = useState("");
  const [usedImage, setUsedImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [productSliderItems, setProductSliderItems] = useState([]);
  const { addItem, toggleCart } = useContext(CartContext);
  const [allImages, setAllImages] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const ProductFetching = async (category) => {
      if ((id && !product.name) || (id && !product.id !== id)) {
        setProduct(await getProductById(id));
        window.scrollTo(0, 0);
        setProductSliderItems(await getProductsByCategory(6, category));
      }
    };
    ProductFetching(collection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, collection]);

  useEffect(() => {
    if (usedImage === "" || usedImage !== product.mainImage) {
      // to change the validations to new key
      if (product?.name) {
        if (product.name) {
          setAllImages((prev) => [product.mainImage, ...product.gallery]);
        }
        setUsedImage(product?.mainImage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (!product?.name) {
    return <LoadingAnimation />;
  }
  return (
    <div className="product">
      <Header isContainer className="header--desktop--v2" />
      <div className="product__back">
        <div
          className="aLink m-0 p-0 cp"
          onClick={() => {
            window.history.back();
          }}
        >
          <i className="ti ti-angle-left product__back__icon"></i>
          <span className="product__back__label">Back</span>
        </div>
      </div>
      {product?.name && (
        <div className="product__wrapper">
          <div className="product__wrapper__left">
            <div className="product__wrapper__left__grid">
              {allImages &&
                allImages?.map((i) => (
                  <img
                    src={i}
                    alt={product?.name ? product?.name : ""}
                    key={i}
                    onClick={() => setUsedImage(i)}
                    className="product__wrapper__left__grid__img"
                  />
                ))}
            </div>
            <img
              src={usedImage}
              alt={product?.name ? product?.name : ""}
              className="product__wrapper__left__img"
            />
          </div>
          <div className="product__wrapper__right">
            <h3 className="product__wrapper__right__title">{product?.name}</h3>
            <p className="product__wrapper__right__price">${product?.price}</p>
            {product?.colors.length > 0 && (
              <div className="product__wrapper__right__attr">
                <span className="product__wrapper__right__quantity__title">
                  Color
                </span>
                <div className="product__wrapper__right__attr__wrapper">
                  {product?.colors?.map((color) => (
                    <div
                      key={color}
                      onClick={(e) => {
                        setColorSelected(color);
                      }}
                      className={`product__wrapper__right__attr__wrapper__item color ${
                        colorSelected === color ? "active" : ""
                      }`}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>
              </div>
            )}
            {product?.sizes.length > 0 && (
              <div className="product__wrapper__right__attr">
                <span className="product__wrapper__right__quantity__title">
                  Size
                </span>
                <div className="product__wrapper__right__attr__wrapper">
                  {product?.sizes.length > 0 &&
                    product?.sizes?.map((size) => (
                      <div
                        key={size}
                        onClick={(e) => {
                          setsizeSelected(size);
                        }}
                        className={`product__wrapper__right__attr__wrapper__item size ${
                          sizeSelected === size ? "active" : ""
                        }`}
                      >
                        {size}
                      </div>
                    ))}
                </div>
              </div>
            )}

            <div className="">
              <span className="product__wrapper__right__quantity__title">
                Quantity
              </span>
              <div className="product__wrapper__right__quantity">
                <div
                  className="product__wrapper__right__quantity__control ti  ti-minus"
                  onClick={() =>
                    quantity !== 1 && setQuantity((prev) => prev - 1)
                  }
                />
                <div className="product__wrapper__right__quantity__control__value">
                  {quantity}
                </div>
                <div
                  onClick={() => setQuantity((prev) => prev + 1)}
                  className="product__wrapper__right__quantity__control ti  ti-plus"
                />
              </div>
            </div>

            <div
              className="product__wrapper__right__btnParent "
              onClick={() => {
                addItem({ quantity, ...product });
                toggleCart(true);
              }}
            >
              <div target="_blank" rel="noreferrer" className="aLink">
                <p className="product__wrapper__right__btnParent__btn cart">
                  Add to Cart
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                addItem({ quantity, ...product });
                navigate("/information");
              }}
              className="product__wrapper__right__btnParent"
            >
              <div target="_blank" rel="noreferrer" className="aLink">
                <p className="product__wrapper__right__btnParent__btn ">
                  buy it now
                </p>
              </div>
            </div>
            <div className="product__wrapper__right__description">
              <span className="product__wrapper__right__quantity__title">
                Description
              </span>
              <p className="product__wrapper__right__description__paragraph">
                {/* {product?.description ? product.description : "N/A"} */}
                {/* {collection === "over-sized-shirts" && !product.description
                  ? "Our tshirts are stitched using the heavy 100% organic coton which is preshrunk giving them a luxurious look and a cool feeling"
                  : collection !== "over-sized-shirts" && !product.description
                  ? "N/A"
                  : product.description} */}

                {collection === "over-sized-shirts" &&
                  "Our tshirts are stitched using the heavy 100% organic coton which is preshrunk giving them a luxurious look and a cool feeling.Experience unmatched comfort and timeless style with our meticulously crafted t-shirts. We take pride in using only the finest 100% organic cotton, ensuring a luxurious feel and long-lasting durability. Each t-shirt is thoughtfully stitched with precision, utilizing preshrunk fabric to maintain its shape and fit even after multiple washes. Whether you're dressing up or going for a casual look."}
                {collection === "shorts" &&
                  "Our shorts are crafted with high-quality materials to provide comfort and style. Made from a blend of durable fabrics, they offer a perfect fit and excellent breathability. Whether you're lounging at home or heading out for a casual day out, our shorts are designed to keep you cool and stylish all day long."}
                {collection === "bags" &&
                  "Our tote bags are the perfect blend of fashion and functionality. Made from sturdy and eco-friendly materials, they are designed to withstand everyday use while minimizing environmental impact. With ample storage space and comfortable handles, our tote bags are versatile and ideal for carrying your essentials, whether you're going to the beach, shopping, or running errands."}
              </p>
            </div>
          </div>
        </div>
      )}
      <ProductsSlider
        titleLabel="IMPERFECT PRODUCTS"
        subTitleLabel="SUMMER 2023 COLLECTION"
        category={product.collection}
        productSliderItems={productSliderItems.filter(
          (i) => i.id !== Number(id)
        )}
      />
    </div>
  );
};

export default Product;
