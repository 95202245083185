import React from "react";
import SubTitle from "../SubTitle";
import Card from "../Card";
import Title from "../Title";
import Button from "../Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.scss";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";

const ProductsSlider = ({
  productSliderItems,
  category,
  subTitleLabel = "NEW WINTER 2023 COLLECTION",
  titleLabel = "LUXURY ESSENTIALISM",
  showButton = true,
}) => {
  return (
    <div className="productsSlider">
      <div className="productsSlider__wrapper">
        <SubTitle className="" label={subTitleLabel} />
        <Title className="" label={titleLabel} />
        <Swiper
          className="productsSlider__wrapper__slider"
          slidesPerView={1.7}
          freeMode={true}
          modules={[FreeMode]}
          breakpoints={{
            768: {
              slidesPerView: 4,
            },
          }}
        >
          {productSliderItems.map((item) => (
            <SwiperSlide
              key={item.id}
              className="productsSlider__wrapper__slider__slideItem"
            >
              <Link key={item.id} to={`/product/${item.collection}/${item.id}`}>
                <Card
                  imgClass={
                    category !== "shorts"
                      ? "productsSlider__wrapper__slider__slideItem__img"
                      : ""
                  }
                  id={item.id}
                  img={item.mainImage}
                  name={item.name}
                  price={item.price}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        {showButton && (
          <Button
            className="productsSlider__wrapper__btn"
            href={`/collection/${category}`}
            label="DISCOVER MORE"
            variant="classic"
          />
        )}
      </div>
    </div>
  );
};

export default ProductsSlider;
