import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const MobileNavItem = ({ item, isMobileHeaderOpen, setIsMobileHeaderOpen }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setIsExpanded(false);
  }, [isMobileHeaderOpen]);
  return (
    <div className="mobileNavItem">
      <div
        className={`mobileNavItem__wrapper  ${
          isMobileHeaderOpen && "mobileNavItem__wrapper--show"
        }`}
      >
        <div className="mobileNavItem__wrapper__flex">
          {item.items.length < 1 ? (
            <Link to={item.link}>
              <p
                className="mobileNavItem__wrapper__label"
                onClick={() => {
                  setIsExpanded((prev) => !prev);
                }}
              >
                {item.label}
              </p>
            </Link>
          ) : (
            <p
              className="mobileNavItem__wrapper__label"
              onClick={() => {
                setIsExpanded((prev) => !prev);
              }}
            >
              {item.label}
            </p>
          )}
          {item.items.length > 0 && (
            <i
              className={`mobileNavItem__wrapper__expand ti ${
                isExpanded ? "ti-angle-up" : "ti-angle-down"
              }`}
            />
          )}
        </div>
        {item.items.length > 0 && (
          <div
            style={{
              height: isExpanded ? `${item.items.length * 43}px` : "0px",
            }}
            className={`mobileNavItem__wrapper__list ${
              isExpanded && `mobileNavItem__wrapper__list--show`
            }`}
          >
            {item.items.map((child) => (
              <Link
                key={child.id}
                to={child.link}
                onClick={() => {
                  setIsMobileHeaderOpen(false);
                  setIsExpanded(false);
                }}
                className="mobileNavItem__wrapper__list__item"
              >
                {child.label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavItem;
