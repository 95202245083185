import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";
import firebase from "../config/firebase";
import emailjs from "emailjs-com";

export const getAllProducts = async () => {
  const db = getFirestore(firebase);
  const productsCollection = query(collection(db, "imperfect_products_5"));
  const querySnapshot = await getDocs(productsCollection);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};
export const getProductById = async (id) => {
  const db = getFirestore(firebase);
  const productsCollection = query(
    collection(db, "imperfect_products_5"),
    where("id", "==", Number(id))
  );
  const querySnapshot = await getDocs(productsCollection);
  const [out] = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
  return out;
};
export const getProductsByCategory = async (limitValue, category) => {
  const db = getFirestore(firebase);
  const productsCollection = query(
    collection(db, "imperfect_products_5"),
    where("collection", "==", category),
    limit(limitValue)
  );
  const querySnapshot = await getDocs(productsCollection);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};
export const getAllProductsByCategory = async (category) => {
  const db = getFirestore(firebase);
  const productsCollection = query(
    collection(db, "imperfect_products_5"),
    where("collection", "==", category)
  );
  const querySnapshot = await getDocs(productsCollection);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};

export const getProductsWithLimit = async (limitValue) => {
  const db = getFirestore(firebase);
  const productsCollection = query(
    collection(db, "imperfect_products_5"),
    limit(limitValue)
  );
  const querySnapshot = await getDocs(productsCollection);
  const productsData = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
  return productsData;
};

export const getProductsByCategoryPagination = async (
  category,
  pageSize,
  lastDocument
) => {
  const db = getFirestore(firebase);
  let productsCollection = query(
    collection(db, "imperfect_products_5"),
    where("collection", "==", category),
    limit(pageSize)
  );
  if (lastDocument) {
    productsCollection = query(productsCollection, startAfter(lastDocument));
  }
  const querySnapshot = await getDocs(productsCollection);
  const productsData = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { productsData, lastVisible };
};

export const getProductsBySearch = async (searchQuery) => {
  const db = getFirestore(firebase);
  const productsCollection = query(
    collection(db, "imperfect_products_5"),
    where("name_lowercase", ">=", searchQuery.toLowerCase()),
    where("name_lowercase", "<=", `${searchQuery.toLowerCase()}\uf8ff`)
  );
  const querySnapshot = await getDocs(productsCollection);
  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};

export const getProductsByPrice = async (filterBy, filterType, category) => {
  // id , ASC , shorts
  const db = getFirestore(firebase);
  if (category === "all") {
    const productsCollection = query(
      collection(db, "imperfect_products_5"),
      orderBy(filterBy, filterType)
    );
    const querySnapshot = await getDocs(productsCollection);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
  } else {
    const productsCollection = query(
      collection(db, "imperfect_products_5"),
      where("collection", "==", category),
      orderBy(filterBy, filterType)
    );
    const querySnapshot = await getDocs(productsCollection);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
  }
};

export const createCoinbaseCharge = async (amount) => {
  try {
    const data = {
      pricing_type: "fixed_price",
      local_price: {
        amount,
        currency: "usd",
      },
    };

    const response = await fetch("https://api.commerce.coinbase.com/charges", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CC-Api-Key": "ed3550e7-5d12-497b-810e-47f7605e8735",
        "X-CC-Version": "2021-03-15",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    return { url: responseData.data.hosted_url, code: responseData.code };
  } catch (error) {
    console.error(error);
  }
};

// Usage example

export const checkTransactionStatus = async (chargeId) => {
  try {
    const response = await fetch(
      `https://api.commerce.coinbase.com/charges/${chargeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-CC-Api-Key": "ed3550e7-5d12-497b-810e-47f7605e8735",
          "X-CC-Version": "2021-03-15",
        },
      }
    );
    const data = await response.json();
    const status = data.data?.timeline[0]?.status;
    if (status) {
      return status;
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendEmail = (msg, name) => {
  const data = {
    to_name: "John Doe",
    from_name: `New Order from ${name}`,
    message: msg,
    // sendTo: "malek0x1@gmail.com",
    sendTo: "imperfect.clothing.lb@gmail.com",
  };

  emailjs
    .send("service_oe07ugf", "template_82dcquu", data, "z7lmlGclS8YJbgb6F")
    .then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
};
