import React from "react";
import "./style.scss";
import HeroContent from "../HeroContent";
const HomeCustomSection = () => {
  return (
    <div className="homeCustomSection">
      <div className="homeCustomSection__wrapper">
        <div
          className="homeCustomSection__wrapper__item"
          style={{
            backgroundImage: 'url("/static/images/10.jpeg")',
          }}
        >
          <HeroContent
            subTitleLabel="PERFECTION IN EVERY DETAIL"
            titleLabel="IMPERFECT T-SHIRTS"
            titleClass="homeCustomSection__wrapper__item__title"
            btnClass="hero__wrapper__background__content__btn"
            btnLabel="View products"
            href="/collection/over-sized-shirts"
          />
        </div>
        <div
          className="homeCustomSection__wrapper__item"
          style={{
            backgroundImage: 'url("/static/images/11.jpeg")',
          }}
        >
          <HeroContent
            subTitleLabel="Essential"
            titleLabel="IMPERFECT TOTE BAGS"
            titleClass="homeCustomSection__wrapper__item__title"
            btnClass="hero__wrapper__background__content__btn"
            btnLabel="View products"
            href="/collection/bags"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCustomSection;
