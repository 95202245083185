import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import Input from "../../Components/Input";
import "./style.scss";
import Button from "../../Components/Button";
import Title from "../../Components/Title";
import OrderOverview from "../../Components/OrderOverview";
import LoadingAnimation from "../../Components/LoadingAnimation";
import RadioInput from "../../Components/RadioInput";
import { countries, shippingMethods, COUPON__CODE } from "../../data/data";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Information = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isCoupon, setIsCoupon] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    zipCode: "",
    country: "Lebanon",
    ratio: "",
    shippingCost: 3,
    zone: 1,
    coupon: "",
  });
  const showToastMessage = () => {
    toast.success("Coupon Applied !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const handleCoupon = (e) => {
    if (e.target.value.toUpperCase() === COUPON__CODE) {
      setPercentage(20);
      showToastMessage();
      setFormData((prev) => {
        return { ...prev, coupon: "yes", percentage: 20 };
      });
    } else {
      setPercentage(0);
    }
  };
  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
    if (name === "country") {
      setFormData((prev) => {
        return {
          ...prev,
          shippingCost:
            e.target.options[e.target.selectedIndex].getAttribute("cost"),
          zone: e.target.options[e.target.selectedIndex].getAttribute("zone"),
        };
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/payment", { state: { formData } });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="information">
      {loading && <LoadingAnimation />}
      <Header isContainer className="header--desktop--v2" />
      <Title className="information__wrapper__title " label="Billing Details" />
      <form onSubmit={handleSubmit} className="information__wrapper">
        <div className="information__wrapper__left">
          <ToastContainer style={{ zIndex: 999999999999999999999999999999 }} />
          <Input
            onChange={handleFormData}
            name="firstName"
            required={true}
            placeholder="First Name"
          />
          <Input
            onChange={handleFormData}
            name="lastName"
            required={true}
            placeholder="Last Name"
          />
          <Input
            onChange={handleFormData}
            name="email"
            type="email"
            required={true}
            placeholder="Email Address"
          />
          <Input
            onChange={handleFormData}
            name="phoneNumber"
            required={true}
            placeholder="Phone"
          />
          <Input
            onChange={handleFormData}
            name="address"
            required={true}
            placeholder="Address"
          />
          <Input
            onChange={handleFormData}
            name="city"
            required={true}
            placeholder="City"
          />
          <Input
            onChange={handleFormData}
            name="zipCode"
            placeholder="Postal code"
          />
          <select
            onChange={handleFormData}
            name="country"
            className="information__wrapper__select"
            defaultValue="Lebanon"
          >
            {countries.map((c) => (
              <option zone={c.zone} cost={c.price} key={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          {isCoupon ? (
            <Input
              onChange={(e) => {
                handleFormData(e);
                handleCoupon(e);
              }}
              name="zipCode"
              placeholder="Coupon Code"
            />
          ) : (
            <div
              onClick={() => {
                setIsCoupon(true);
              }}
              className="information__wrapper__couponLabel"
            >
              Have a coupon? Click here to enter your code
            </div>
          )}
        </div>
        <div className="information__wrapper__right">
          <OrderOverview
            coupon={percentage}
            shippingCost={formData.shippingCost}
            className="order-2"
          />
          <div className="information__wrapper__right__btn">
            <h4>Shipping Method</h4>
            <div className="information__wrapper__right__btn__radio">
              {shippingMethods.map((i) => {
                if (Number(i.zone) === Number(formData.zone)) {
                  return (
                    <RadioInput
                      key={i.id}
                      description={i.label}
                      name="shipping"
                      setFormData={setFormData}
                      price={formData.shippingCost}
                      id={i.id}
                    />
                  );
                }
              })}
            </div>
          </div>
          <Button
            wrapperClass="order-3"
            className="information__wrapper__right__btn--btn "
            label="Continue to payment"
            variant="classic"
          />
        </div>
      </form>
    </div>
  );
};

export default Information;
