import React from "react";
import "./style.scss";
const CopyRight = () => {
  return (
    <div className="copyright">
      <p className="copyright__label">
        © 2024 Developed by{" "}
        {/* <a className="copyright__label__link" href="https://www.syntaxify.dev/"> */}
        Malek Mneimneh
        {/* </a> */}
      </p>
    </div>
  );
};

export default CopyRight;
