import { Link } from "react-router-dom";
import React from "react";
import "./style.scss";

const Button = ({
  variant,
  className,
  label,
  href,
  onClick = () => {},
  wrapperClass = "",
}) => {
  if (variant === "modern") {
    return (
      <Link className={`btn__fancy ${className ? className : ""}`} to={href}>
        <span className="btn__fancy__top-key"></span>
        <span className="btn__fancy__text">{label}</span>
        <span className="btn__fancy__bottom-key-1"></span>
        <span className="btn__fancy__bottom-key-2"></span>
      </Link>
    );
  } else {
    return (
      <div
        className={`btn__classic__wrapper ${
          wrapperClass ? wrapperClass : "wrapperClass"
        }`}
      >
        {href ? (
          <Link to={`${href}`}>
            <button
              onClick={onClick}
              type="submit"
              className={`btn__classic__wrapper__btn ${
                className ? className : ""
              }`}
            >
              {label}
            </button>
          </Link>
        ) : (
          <button
            onClick={onClick}
            type="submit"
            className={`btn__classic__wrapper__btn  ${
              className ? className : ""
            }`}
          >
            {label}
          </button>
        )}
      </div>
    );
  }
};

export default Button;
