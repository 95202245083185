import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Card from "../../Components/Card";
import LoadingAnimation from "../../Components/LoadingAnimation";
import "./style.scss";
import { Link, useParams } from "react-router-dom";
import {
  getAllProducts,
  getAllProductsByCategory,
  getProductsByPrice,
} from "../../Utils/fetching";

const Collection = () => {
  const [isSortByOpen, setSortByOpen] = useState(false);
  const { collection } = useParams();

  const [products, setProducts] = useState(false);
  const handleSortBy = async (e) => {
    const { innerHTML: value } = e.target;
    setSortByOpen((prev) => !prev);
    switch (value) {
      case "Alphabetically, A-Z":
        await getProductsByPrice("name", "asc", collection).then((res) =>
          setProducts(res)
        );
        break;
      case "Alphabetically, Z-A":
        await getProductsByPrice("name", "desc", collection).then((res) =>
          setProducts(res)
        );
        break;
      case "Price, low to high":
        await getProductsByPrice("price", "asc", collection).then((res) =>
          setProducts(res)
        );
        break;

      case "Price, high to low":
        await getProductsByPrice("price", "desc", "all").then((res) =>
          setProducts(res)
        );

        break;

      default:
        break;
    }
  };
  useEffect(() => {
    const ProductFetching = async (category) => {
      if (category !== "all") {
        setProducts(await getAllProductsByCategory(category));
        window.scrollTo(0, 0);
      } else {
        setProducts(await getAllProducts());
        window.scrollTo(0, 0);
      }
    };
    if (collection) {
      ProductFetching(collection);
    }
  }, [collection]);

  if (!products) {
    return <LoadingAnimation />;
  }

  return (
    <div className="collection">
      <Header isContainer className="header--desktop--v2" />
      {products?.length > 0 ? (
        <div className="collection__wrapper">
          <div className="collection__wrapper__filterWrapper__parent">
            <div
              className={`collection__wrapper__filterWrapper__sortParent ${
                isSortByOpen
                  ? "collection__wrapper__filterWrapper__sortParent--open"
                  : ""
              }`}
            >
              {[
                "Alphabetically, A-Z",
                "Alphabetically, Z-A",
                "Price, low to high",
                "Price, high to low",
              ].map((i) => (
                <div
                  key={i}
                  onClick={handleSortBy}
                  className="collection__wrapper__filterWrapper__sortParent__item"
                >
                  {i}
                </div>
              ))}
            </div>
            <div
              className="collection__wrapper__filterWrapper__parent__left"
              onClick={() => {
                setSortByOpen((prev) => !prev);
              }}
            >
              <i className="collection__wrapper__filterWrapper__parent__left__icon ti ti-filter"></i>
              Filter and Sort
            </div>
            <div className="collection__wrapper__filterWrapper__parent__right">
              {products?.length ? (
                `${products?.length} products`
              ) : (
                <div className="loader-count"></div>
              )}
            </div>
          </div>
          <div className="collection__wrapper__products">
            {products?.length > 0 &&
              products?.map((item, index) => (
                <div
                  key={item.id}
                  className="collection__wrapper__products__item"
                >
                  <Link
                    // data-aos="fade-up"
                    // data-aos-delay={`${(index % 2) * 300}`}
                    key={item.id}
                    to={`/product/${item.collection}/${item.id}`}
                  >
                    <Card
                      id={item.id}
                      img={item.mainImage}
                      name={item.name}
                      price={item.price}
                    />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <p>No Products Found !</p>
      )}
    </div>
  );
};

export default Collection;
