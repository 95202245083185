import React, { useContext, useEffect } from "react";
import "./style.scss";
import { CartContext } from "../../context/cartContext";

const OrderOverview = ({ className = "", shippingCost, coupon = 0 }) => {
  const { cart } = useContext(CartContext);
  const getTotalPrice = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total;
  };
  let LastPrice = getTotalPrice() + Number(shippingCost);
  useEffect(() => {
    // if (cart.length === 0) {
    //   window.location = "/";
    // }
  }, []);
  return (
    <div className={`orderOverview ${className}`}>
      <div className="orderOverview__wrapper">
        <h4 className="orderOverview__wrapper__title">Your Order</h4>
        <div className="orderOverview__wrapper__subtotalSect">
          <p className="orderOverview__wrapper__subtotalSect__product">
            Products
          </p>
          <p className="orderOverview__wrapper__subtotalSect__subtotal">
            {/* Prices */}
          </p>
        </div>
        <div className="orderOverview__wrapper__products">
          {cart.map((item) => (
            <div className="orderOverview__wrapper__products__item">
              <p className="orderOverview__wrapper__products__item__name">
                {item.name} &times; {item.quantity}
              </p>
              <p className="orderOverview__wrapper__products__item__price">
                {item.price}$
              </p>
            </div>
          ))}
        </div>
        <div className="orderOverview__wrapper__footer">
          <div className="orderOverview__wrapper__footer__subtotal">
            <p className="orderOverview__wrapper__footer__subtotal__label">
              Subtotal
            </p>
            <p className="orderOverview__wrapper__footer__subtotal__price">
              {`${getTotalPrice()}$`}
            </p>
          </div>
          <div className="orderOverview__wrapper__footer__shipping">
            <p className="orderOverview__wrapper__footer__shipping__label">
              Shipping
            </p>
            <p className="orderOverview__wrapper__footer__shipping__price">
              {shippingCost}$
            </p>
          </div>
          <div className="orderOverview__wrapper__footer__total">
            <p className="orderOverview__wrapper__footer__total__label">
              Total
            </p>
            <p className="orderOverview__wrapper__footer__total__price">{`${
              LastPrice - LastPrice * (coupon / 100)
            }$`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOverview;
