import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header";
import Title from "../../Components/Title";
import LoadingAnimation from "../../Components/LoadingAnimation";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);

      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="about">
      {loading && <LoadingAnimation />}

      <Header isContainer className="header--desktop--v2" />
      <div className="about__wrapper">
        <Title className="" label="About Us" />
        <p className="about__wrapper__paragraph">
          At IMPERFECT, we believe in embracing imperfections and celebrating
          individuality. Our brand was born out of the idea that perfection is
          overrated and that true beauty lies in our unique qualities. We strive
          to create a community that values authenticity, self-expression, and
          the beauty of imperfections.
          <br />
          <br /> Our Mission: Our mission is to empower individuals to embrace
          their imperfections and live life on their own terms. We want to
          challenge societal norms and beauty standards by promoting
          self-acceptance and encouraging people to be comfortable in their own
          skin. We believe that everyone has a story to tell, and it's the
          quirks and flaws that make us who we are. <br />
          <br />
          Our Products: IMPERFECT offers a range of products that reflect our
          values and philosophy. From clothing and accessories to home decor,
          each item is designed to inspire and uplift. Our products celebrate
          diversity, creativity, and the beauty of imperfections. We believe in
          quality craftsmanship, sustainable practices, and supporting local
          artisans whenever possible.
          <br />
          <br /> Our Community: At IMPERFECT, we are more than just a brand; we
          are a community. We strive to create a safe and inclusive space where
          individuals can connect, share their stories, and inspire each other.
          We encourage our community members to be confident, embrace their
          flaws, and pursue their passions with unwavering determination.
          <br />
          <br />
          Sustainability: We are committed to sustainable and ethical practices
          in all aspects of our business. From sourcing materials to production
          and packaging, we prioritize eco-friendly choices. We believe in
          leaving a positive impact on the planet and reducing our carbon
          footprint. By choosing IMPERFECT, you're supporting a brand that cares
          about the environment and future generations. Join the IMPERFECT
          Movement: We invite you to be a part of the IMPERFECT movement and
          celebrate the beauty in imperfection.
          <br />
          <br />
          Follow us on social media, join our community, and explore our
          collection of thoughtfully designed products. Together, let's redefine
          beauty standards and create a world where imperfections are not only
          accepted but celebrated. Thank you for joining us on this journey of
          self-discovery and embracing imperfection. We can't wait to embark on
          this exciting adventure with you! Remember, at IMPERFECT, you are
          perfect just the way you are.
        </p>
      </div>
    </div>
  );
};

export default About;
