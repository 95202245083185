import React, { useContext, useEffect, useState } from "react";
import Cart from "../Cart/index";
import { navigation } from "../../data/data";
import { Link } from "react-router-dom";
import MobileHeader from "../MobileHeader";
import "./style.scss";
import Search from "../Search";
import { CartContext } from "../../context/cartContext";

const Header = ({ className, isContainer = false }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isCartOpened, toggleCart, cart } = useContext(CartContext);
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // if (isCartOpened || isMobileHeaderOpen || isSearchOpen) {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "auto";
  // }
  return (
    <div className={`${isContainer ? "container" : ""}`}>
      <div
        className={`header--desktop ${className ? className : ""} ${scrollPosition ? "header--desktop--scroll" : ""
          }`}
      >
        <Search isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
        <Cart isCartOpen={isCartOpened} setIsCartOpen={toggleCart} />
        <MobileHeader
          isMobileHeaderOpen={isMobileHeaderOpen}
          setIsMobileHeaderOpen={setIsMobileHeaderOpen}
        />
        {(isCartOpened || isMobileHeaderOpen || isSearchOpen) && (
          <div
            onClick={() => {
              setIsMobileHeaderOpen(false);
              toggleCart(false);
              setIsSearchOpen(false);
            }}
            className="overlay"
          ></div>
        )}
        <div className="header--desktop__wrapper">
          <i
            onClick={() => setIsMobileHeaderOpen((prev) => !prev)}
            className="header--desktop__wrapper__hamburger ti  ti-align-justify "
          ></i>

          <div className="header--desktop__wrapper__links">
            {navigation.map((i) => (
              <div className="" key={i.id} style={{ position: "relative" }}>
                <h3 className="header--desktop__wrapper__links__link">
                  <Link
                    className="header--desktop__wrapper__links__link__label"
                    to={i.link}
                  >
                    {i.label}
                  </Link>
                </h3>
                {i.items.length > 0 && (
                  <div className="header--desktop__wrapper__links__dropdown">
                    {i.items.map((child) => (
                      <Link key={child.id} to={child.link}>
                        <li className="header--desktop__wrapper__links__dropdown__item">
                          {child.label}
                        </li>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <Link to="/">
            <h2 className="header--desktop__wrapper__title">imperfect</h2>
          </Link>
          <div className="header--desktop__wrapper__icons">
            <i
              className="header--desktop__wrapper__icons__icon ti ti-search"
              onClick={() => setIsSearchOpen(true)}
            ></i>
            <i
              onClick={() => {
                toggleCart(true);
              }}
              className={`header--desktop__wrapper__icons__icon ti ti-bag ${cart.length > 0 ? "isProducts" : ""
                }`}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
