import React from "react";
import "./style.scss";
const Card = ({ img, name, price, id, imgClass }) => {
  return (
    <div data-aos="fade-up" key={id} className="card">
      <div className="card__wrapper">
        <img
          src={img}
          alt={name}
          loading="lazy"
          className={`card__wrapper__img ${imgClass ? imgClass : ""}`}
        />
        <p className="card__wrapper__name">{name}</p>
        <p className="card__wrapper__price">{Number(price)}$</p>
      </div>
    </div>
  );
};

export default Card;
