import React from "react";
import Title from "../Title/";
import SubTitle from "../SubTitle";
import Button from "../Button";
import "./style.scss";

const HeroContent = ({
  titleClass = "",
  btnClass = "",
  href = "",
  titleLabel = "LUXURY ESSENTIALISM",
  subTitleLabel = "UP TO 50% OFF",
  btnLabel = "Shop Now",
}) => {
  return (
    <div className="heroContent">
      <div className="heroContent__wrapper">
        <SubTitle
          label={subTitleLabel}
          className="heroContent__wrapper__subtitle"
        />
        <Title
          className={`heroContent__wrapper__title ${
            titleClass ? titleClass : ""
          }`}
          label={titleLabel}
        />
        <Button
          className={btnClass}
          href={href}
          label={btnLabel}
          variant="classic"
        />
      </div>
    </div>
  );
};

export default HeroContent;
