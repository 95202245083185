import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header";
import Title from "../../Components/Title";
import LoadingAnimation from "../../Components/LoadingAnimation";

const ShippingAndReturnPolicy = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);

      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="about">
      {loading && <LoadingAnimation />}

      <Header isContainer className="header--desktop--v2" />
      <div className="about__wrapper">
        <Title
          className=""
          label="Shipping policy
"
        />
        <p className="about__wrapper__paragraph">
          At Imperfect LB, we value our customers and want to ensure their
          satisfaction with our products and services. This refund policy
          explains how we handle returns, refunds, and exchanges to provide you
          with a hassle-free shopping experience. Please read this policy
          carefully before making a purchase. 30-Day Money-Back Guarantee We are
          proud to offer a 30-day money-back guarantee on all purchases made
          through our website. If you are not 100% satisfied with your purchase,
          you may request a refund within 30 days from the date of purchase. We
          believe this timeframe allows you ample opportunity to try our product
          or service and determine if it meets your expectations. Eligibility
          <p className="tas">
            for Refund To be eligible for a refund, the following conditions
            must be met:
          </p>
          <ul className="policy__list">
            <il>
              The refund request must be submitted within 30 days from the date
              of purchase.
            </il>
            <il>
              The product or service must be in its original condition,
              including all packaging, documentation, and accessories.
            </il>
            <il>
              Any bundled items or promotional gifts included with the purchase
              must also be returned.
            </il>
          </ul>
          <p className="tas">How to Request a Refund:</p>
          <br />
          To request a refund, please contact our customer support team via
          imperfect.clothing.lb@gmail.com. Provide them with your order
          information and a brief explanation of why you are requesting a
          refund. Our team will review your request and respond to you within 24
          hours. <br />
          <p className="tas">Refund Process:</p>
          <br />
          Once your refund request is approved, we will initiate the refund
          process. The refund will be issued to the original payment method used
          during the purchase. Please note that it may take 1-5 days for the
          refund to reflect in your account, depending on your payment provider.
          Exchanges In addition to refunds, we also offer the option to exchange
          your purchase for another product or service of equal or lesser value.
          If you prefer an exchange, please indicate this in your refund
          request, and our customer support team will assist you accordingly.
          <br />
          <p className="tas">Exceptions:</p>
          <br />
          While we strive to provide a seamless refund experience, there are
          certain situations where we may not be able to offer a refund:
          <ul className="policy__list">
            <li>
              Products or services that have been explicitly marked as
              non-refundable
            </li>
            <li>
              Products or services that have been customized or personalized
              according to your specifications
            </li>
            <li>
              Downloadable digital products that have been accessed or
              downloaded.
            </li>
            <li>Services that have already been rendered or utilized </li>
          </ul>
          <br />
          <p className="tas">Changes to the Refund Policy</p> We reserve the
          right to modify or update this refund policy at any time without prior
          notice. Any changes will be effective immediately upon posting the
          revised policy on our website. We encourage you to review this policy
          periodically to stay informed about our refund practices.
          <p className="tas">Contact Us:</p>
          If you have any questions or concerns regarding our refund policy,
          please don't hesitate to contact our customer support team. We are
          here to assist you and ensure your satisfaction. Thank you for
          choosing Imperfect LB. We appreciate your trust and look forward to
          serving you
        </p>

        {/* <p className="about__wrapper__paragraph">
          Imperfect aims to ship out your order as quickly as possible. Items
          ship same or next day. We appreciate your patience and always do our
          best to ship quickly. Typically, if your order was placed Monday
          through Thursday it will be shipped within 1-2 days, however at times
          it may take 3-5 days to ship. If your order was placed on Friday, the
          order will likely ship within 3-5 days. You will receive an e-mail
          confirming your order has shipped. We appreciate your patience! We
          have an extremely high overall customer service and satisfaction
          rating for a reason :<br />
          <p className="tas">
            Please ensure you report an issue within the respective deadlines:
          </p>
          <ul className="policy__list">
            <li>
              Damaged: Within 3 days of when the package was marked delivered
            </li>
            <li>
              Defective: Within 7 days of when the package was marked delivered{" "}
            </li>
            <li>
              Missing: Within 7 days of when the package was marked delivered{" "}
            </li>
            <li>
              Quality: Within 30 days of when the package was marked delivered
            </li>
          </ul>
          <br />
          <b>INTERNATIONAL SHIPPING:</b>
          <br />
          <br />
          International shipping postage simply covers the cost of transporting
          and delivering your parcel. Imperfect does not charge any applicable
          duties and taxes imposed by the customs and revenue authorities in the
          destination country. This is the responsibility of the customer. There
          are different costs per destination, but we strive to keep the charges
          as minimal as possible. Duties (or Customs Tariffs)are set by the
          destination country customs authorities and determined based on a
          combination of country of origin (manufacturing) of the goods being
          purchased and the classification of that merchandise. International
          taxes (such as value added, tax, or vat) are set by the destination
          country.
          <br />
          <br />
          <b className="tas">Returns:</b>
          <br />
          <br />
          Reserved Rights Regarding Orders/Returns We reserve the right to
          solely define and limit, refuse, and/or reject orders/returns from
          customers at any time due to:.
          <ul className="policy__list">
            <li>
              An irregular or excessive returns history indicative of
              "wardrobing;"
            </li>
            <li>
              An irregular or excessive returns history involving worn, altered,
              laundered, damaged, or missing items; or
            </li>
            <li>Potential fraudulent or criminal activity.</li>
          </ul>
          <br />
          Returned-to-Sender & Refused Packages Returned-to-Sender or Refused
          packages will be restocked and you will be issued store credit in the
          form of an E-Gift Card. We will do our best to get your package
          delivered, but we do not take responsibility for lost, misplaced, or
          incorrectly delivered shipments if the address information provided is
          incorrect at the time of purchase.
          <br />
          Return Exchanges/Store Credit/Refunds <br />
          Returns are accepted within 9 days of order delivery. All returned
          items are inspected before an Exchange/Store Credit/Refund can be
          processed so please ensure that the items are in new, unworn, and
          unwashed condition; to include being free of smells, stains, makeup,
          deodorant, and/or bodily fluids. We reserve the right to refuse
          returns that show signs of wear or do not meet the above criteria.
          <br />
          <p>
            If you are returning an item that has been previously exchanged,
            there are no refunds on any exchange returns - you will be issued a
            store credit in the form of an E-Gift Card. Return Processing Time
            Frame: 5-7 business days once the return package is received. Sales
            items are final and are not eligible for returns, refunds, or
            exchanges.
          </p>
          <br />
          <b> How to Return:</b>
          <br />
          Please contact us to create a return, stating the reason.
          <br />
          <br />
          <b>For Immediate Questions or Concerns:</b>
          <br />
          Customer Service: imperfect.clothing.lb@gmail.com Phone Support:
          +(961) 71 379 531 Monday-Friday from 9am-5pm GMT +3
        </p> */}
      </div>
    </div>
  );
};

export default ShippingAndReturnPolicy;
