import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header";
import RadioInput from "../../Components/RadioInput";
import { PaymentMethods } from "../../data/data";
import Button from "../../Components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingAnimation from "../../Components/LoadingAnimation";
import {
  checkTransactionStatus,
  createCoinbaseCharge,
  sendEmail,
} from "../../Utils/fetching";
import { CartContext } from "../../context/cartContext";

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);

  if (location?.state == null) {
    // navigate("/");
    window.location.href = "/"; // to fix
  }
  const { formData } = location.state;
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState({ ratio: "" });

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "auto";
    };
  }, []);
  const emailProductsHandle = () => {
    let products = ``;
    cart.forEach((item) => {
      products += `
      Product Name: ${item.name} x ${item.quantity}
      `;
    });
    return products;
  };
  const getTotalPrice = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total;
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    if (paymentMethod.ratio === "Cash on Delivery (COD)") {
      sendEmail(
        `Customer Email: ${formData.email}
      Customer Name: ${formData.firstName} ${formData.lastName}
      Address: ${formData.country} / ${formData.city} / ${formData.address} / ${
          formData.zipCode
        }
      email: ${formData.email}
      shipping: ${formData.ratio}
      phone: ${formData.phoneNumber}
      Products:
      ${emailProductsHandle()}
      coupon applied: ${formData.coupon}
      total: ${getTotalPrice()}$
      `,
        formData.firstName
      );
      navigate("/order-received", { state: { formData } });
    } else if (paymentMethod.ratio === "Coinbase Cryptocurrency") {
      const coinbaseCharge = await createCoinbaseCharge(getTotalPrice());
      window.open(coinbaseCharge.url);
      const check = await checkTransactionStatus(coinbaseCharge.code);
      if (check === "CONFIRMED") {
        sendEmail(
          `Customer Email: ${formData.email}
        Customer Name: ${formData.firstName} ${formData.lastName}
        Address: ${formData.country} / ${formData.city} / ${
            formData.address
          } / ${formData.zipCode}
        email: ${formData.email}
        shipping: ${formData.ratio}
        phone: ${formData.phoneNumber}
        Products:
        ${emailProductsHandle()}
        total: ${getTotalPrice()}$

        Payment: ${paymentMethod.ratio}
        `,
          formData.firstName
        );
        navigate("/order-received");
      }
    }
  };
  return (
    <div className="payment">
      {loading && <LoadingAnimation />}

      <Header isContainer className="header--desktop--v2" />
      <form onSubmit={handlePayment} className="payment__wrapper">
        <div className="payment__wrapper__details">
          <div className="payment__wrapper__details__item">
            <div className="payment__wrapper__details__item__title">
              Contact
            </div>
            <div className="payment__wrapper__details__item__label">
              {formData.email}
            </div>
          </div>
          <div className="payment__wrapper__details__item">
            <div className="payment__wrapper__details__item__title">
              Ship to
            </div>
            <div className="payment__wrapper__details__item__label">
              {`${formData.country || "Lebanon"}, ${formData.city}, ${
                formData.address
              }`}
            </div>
          </div>
          <div className="payment__wrapper__details__item">
            <div className="payment__wrapper__details__item__title">
              Shipping
            </div>
            <div className="payment__wrapper__details__item__label">
              {formData.ratio} - ${formData.shippingCost}
            </div>
          </div>
        </div>
        <h4>Payment Method</h4>
        {console.log(formData)}
        <div className="information__wrapper__right__btn__radio">
          {formData.country === "Lebanon"
            ? PaymentMethods.map((i) => (
                <RadioInput
                  key={i.id}
                  description={i.label}
                  name="payment"
                  setFormData={setPaymentMethod}
                  price={i.price}
                  id={i.id}
                />
              ))
            : PaymentMethods.map((i) => {
                if (i.id !== 3) {
                  return (
                    <RadioInput
                      key={i.id}
                      description={i.label}
                      name="payment"
                      setFormData={setPaymentMethod}
                      price={i.price}
                      id={i.id}
                    />
                  );
                }
              })}
        </div>
        <Button
          className="information__wrapper__right__btn--btn"
          label="Complete Order"
          variant="classic"
          // onClick={}
        />
      </form>
    </div>
  );
};

export default Payment;
