import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../Components/Header";
import Title from "../../Components/Title";
import LoadingAnimation from "../../Components/LoadingAnimation";

const TermsOfService = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 1000);

    document.body.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);

      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="about">
      {loading && <LoadingAnimation />}

      <Header isContainer className="header--desktop--v2" />
      <div className="about__wrapper">
        <Title className="" label="Terms of service" />
        <p className="about__wrapper__paragraph">
          <p className="tas">DISCLAIMER:</p>
          Imperfect store's ambition is for the website to always be up and
          running, however Imperfect store cannot guarantee this. Imperfect
          store cannot guarantee that all the products shown are available at
          the time you place your order. In the event the product you order is
          no longer available you will be contacted by e-mail shortly after
          placing your order.
          <br />
          Due to the color settings of your computer, the colors of the products
          shown in the pictures in the Imperfect store can differ from their
          actual colors. Imperfect store cannot be held responsible for any such
          discrepancy.
          <br />
          <p className="tas">PRICE CHANGES:</p>
          Imperfect store reserves the right to change prices of the products
          sold. The prices of the products will, during the time of placing an
          order, be valid throughout that specific process.
          <br />
          <p className="tas">PAYMENT:</p>
          Imperfect store accepts payment by VISA / MasterCard. The full payment
          is charged when the package is dispatched. Credit card payment is made
          with our partner Paygate. All transactions are encrypted and 100%
          secure.
          <br />
          <p className="tas">SHIPPING CHARGES</p>
          Shipping charges will be added to your order. Please view Imperfect
          store shipping rates for information on your specific shipping charge.
          <br />
          <p className="tas">CURRENCIES</p>
          Imperfect store cannot be held responsible for any currency changes or
          currency exchange rates any bank may have when exchanging the EUR, USD
          or GBP into any other currency..
          <br />
          <p className="tas">UNCOLLECTED PACKAGES</p>
          Imperfect store will not pay shipping costs for uncollected packages..
          <br />
          <p className="tas">CONTACT INFORMATION TO CUSTOMER SERVICE</p>
          If you have any questions please contact the customer service at
          imperfect.clothing.lb@gmail.com
          <br />
          <p className="tas">FEEDBACK AND COMPLAINTS POLICY</p>
          We welcome any comments about our service, so that we can improve what
          we are doing. We aim to deal with any problems quickly and fairly. If
          you have reason to complain about our service, please email our
          Customer Services Team stating clearly that you are making a
          complaint. Our aim is to respond to your complaint within a few
          working days, either resolving the complaint, or with a timescale for
          resolution.
          <br />
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
